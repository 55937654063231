import {createUserWithEmailAndPassword, sendEmailVerification} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getFirestore, query, collection, where, getDocs, doc, setDoc, getDoc, updateDoc, deleteField, onSnapshot } from 'firebase/firestore';
import { browserLocalPersistence, getAuth, setPersistence } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
//import { doc, setDoc } from 'firebase/firestore';
import bcrypt from 'bcryptjs';
// Your web app's Firebase configuration
//const bcrypt = require('bcrypt');
//window.$ = $;
//window.bcryptjs = bcrypt;

//Test environment

const firebaseConfig = {
    apiKey: "AIzaSyBYzmKtwaieGem-mLZ_x2BUrceJU7JIEX0",
    authDomain: "cbuserapp.firebaseapp.com",
    databaseURL:
      "https://cbuserapp-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "cbuserapp",
    storageBucket: "cbuserapp.appspot.com",
    messagingSenderId: "878847920847",
    appId: "1:878847920847:web:176f626072a5caed5e85ba",
    measurementId: "G-G1Y3CMYRWV",
  };

/*
//Production environment
const firebaseConfig = {
  apiKey: "AIzaSyDT1Pdezn7hgPgIcMQ25yzYIj51gBiVH-U",
  authDomain: "cbuserapp-production.firebaseapp.com",
  projectId: "cbuserapp-production",
  storageBucket: "cbuserapp-production.appspot.com",
  messagingSenderId: "193580959879",
  appId: "1:193580959879:web:32882ce1449ed83a795022",
  measurementId: "G-LN8XF3J5WK",
};
*/
  // Initialize Firebase
  // Initialize variables
  //const auth = getAuth(app);
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  
/*
async function getItem(userId, key) {
  try {
      const docSnap = await getDoc(doc(db, 'usersDelivery', userId));
      if (docSnap.exists()) {
          return docSnap.data()[key];
      } else {
          console.log('No data available');
          return null;
      }
  } catch (error) {
      console.error('Error retrieving data:', error);
  }
}

async function removeItem(userId, key) {
  try {
      await updateDoc(doc(db, 'usersDelivery', userId), {
          [key]: deleteField()
      });
      console.log('Data removed successfully.');
  } catch (error) {
      console.error('Error removing data:', error);
  }
}

*/

//const userId = getItem(userId, 'userID')
//Checking if errors have been set
/*
document.addEventListener("DOMContentLoaded", function () {
  function checkEmail(){
  //const email = localStorage.getItem('emailPopup')
  const email = getItem(userId, 'emailPopup').then(value => {
    console.log('Retrieved value:', value); 
  });

  if (email !== null) {
  // JavaScript variable

  // Get the HTML element by its ID
  var displayElement = document.getElementById("displayEmail");

  // Set the innerHTML of the element to the value of the JavaScript variable
  displayElement.innerHTML = email;

  const passMessage = document.getElementById('invalid_password');
  passMessage.style.display = "none";

  const accountMessage = document.getElementById('invalid_account');
  accountMessage.style.display = "none";

  const emailMessage = document.getElementById('invalid_email');
  emailMessage.style.display = "flex";
  //localStorage.removeItem('emailPopup');
  removeItem(userId, 'emailPopup');
  }
}
setInterval(checkEmail, 100);
});

document.addEventListener("DOMContentLoaded", function () {
  function checkPass(){
  //const pass = localStorage.getItem('pass')
  const pass = getItem(userId, 'pass').then(value => {
    console.log('Retrieved value:', value); 
  });
  if (pass !== null) {
  // JavaScript variable

  const emailMessage = document.getElementById('invalid_email');
  emailMessage.style.display = "none";

  const accountMessage = document.getElementById('invalid_account');
  accountMessage.style.display = "none";

  const passMessage = document.getElementById('invalid_password');
  passMessage.style.display = "flex";
  localStorage.removeItem('pass');
  removeItem(userId, 'pass');
  }
}
setInterval(checkPass, 100);
});

document.addEventListener("DOMContentLoaded", function () {
  function checkAccount(){
  //const account = localStorage.getItem('account')
  const account = getItem(userId, 'account').then(value => {
    console.log('Retrieved value:', value); 
  });
  if (account !== null) {
  // JavaScript variable

  const passMessage = document.getElementById('invalid_password');
  passMessage.style.display = "none";

  const emailMessage = document.getElementById('invalid_email');
  emailMessage.style.display = "none";

  const accountMessage = document.getElementById('invalid_account');
  accountMessage.style.display = "flex";
  //localStorage.removeItem('account');
  removeItem(userId, 'account');
  }
}
setInterval(checkAccount, 100);
});
*/
document.addEventListener("DOMContentLoaded", () => {
function validate_email(email) {
  const expression = /^[^@]+@\w+(\.\w+)+\w$/
  if (expression.test(email) == true) {
    console.log("email True");
    // Email is good
    return true
  } else {
    // Email is not good
    console.log("email False");
    return false
  }
}

function validate_password(password) {
  // Firebase only accepts lengths greater than 6
  if (password.length < 6) {
    console.log("password False");
    return false
  } else {
    console.log("password True");
    return true
  }
}

const emailValue = document.getElementById("email");
const passwordValue = document.getElementById("password");
const loginButton = document.getElementById("loginButton");

function validateForm() {
  const email = document.getElementById("email").value;
  const password = document.getElementById("password").value;
  const emailValid = validate_email(email);
  const passwordValid = validate_password(password)

  if (emailValid && passwordValid) {
    loginButton.classList.add('active');
    loginButton.classList.remove('no-click-cursor');
    loginButton.style.cursor = 'pointer';
    console.log("Valid email and password case");
    //loginButton.disabled = false;
  } else {
    loginButton.classList.remove('active');
    loginButton.classList.add('no-click-cursor');
    loginButton.style.cursor = '';
    console.log("Invalid email and password case");
    //loginButton.disabled = true;
  }
}

emailValue.addEventListener('input', validateForm);
passwordValue.addEventListener('input', validateForm);
validateForm();
});
/*
loginButton.addEventListener("click", function(event) {
  const email = document.getElementById("email").value;
  const password = document.getElementById("password").value;
  const emailValid = validate_email(email);
  const passwordValid = validate_password(password)

  if (emailValid && passwordValid) {
    console.log("valid")
  } else {
  event.preventDefault();
  }
});
*/

console.log("change made")
document.addEventListener("DOMContentLoaded", () => {
//localStorage.removeItem('signedIn');
});
const register = document.getElementById("register")
register.addEventListener('click', function(event) {
  event.preventDefault;
  window.location.href = "https://store.wecarrybags.co.uk/register?redirect=https://delivery.wecarrybags.co.uk/";
  //window.location.href = "https://dashboard-fixes--transcendent-klepon-844136.netlify.app/register?redirect=https://delivery.wecarrybags.co.uk/";
});

//login()
async function login(event) {
  const email = document.getElementById("email").value;
  const email_name = document.getElementById("email").value
  const password = document.getElementById("password").value;
  function validate_email(email) {
    const expression = /^[^@]+@\w+(\.\w+)+\w$/
    if (expression.test(email) == true) {
      console.log("email True");
      // Email is good
      return true
    } else {
      // Email is not good
      console.log("email False");
      return false
    }
  }
  
  function validate_password(password) {
    // Firebase only accepts lengths greater than 6
    if (password.length < 6) {
      console.log("password False");
      return false
    } else {
      console.log("password True");
      return true
    }
  }
  const emailValid = validate_email(email);
  const passwordValid = validate_password(password)

  if (emailValid && passwordValid) {

  function sanitizeEmail(email) {
    return email.replace(/[^a-zA-Z0-9]/g, '_');
  }
  const userEmail = email_name; // Replace this with the actual user email
const userId = sanitizeEmail(userEmail);
sessionStorage.setItem('userId', userId)

async function setItem(userId, key, value) {
  try {
      await setDoc(doc(db, 'usersDelivery', userId), { [key]: value }, { merge: true });
      console.log('Data saved successfully.');
  } catch (error) {
      console.error('Error saving data:', error);
  }
}

async function setItems(userId, keys, value) {
  if (!Array.isArray(keys)) {
      keys = [keys];
  }
  
  // Construct the data object
  const data = keys.reduce((obj, key) => {
      obj[key] = value;
      return obj;
  }, {});

  try {
      await setDoc(doc(db, 'usersDelivery', userId), data, { merge: true });
      console.log('Data saved successfully.');
  } catch (error) {
      console.error('Error saving data:', error);
  }
}
for (let i = 1; i <= 10; i++) {
setItems(userId, ['recipientName' +  i], "")
}


async function getItem(userId, key) {
  try {
      const docSnap = await getDoc(doc(db, 'usersDelivery', userId));
      if (docSnap.exists()) {
          return docSnap.data()[key];
      } else {
          console.log('No data available');
          return null;
      }
  } catch (error) {
      console.error('Error retrieving data:', error);
  }
}

async function removeItem(userId, key) {
  try {
      await updateDoc(doc(db, 'usersDelivery', userId), {
          [key]: deleteField()
      });
      console.log('Data removed successfully.');
  } catch (error) {
      console.error('Error removing data:', error);
  }
}

function listenForChanges(userId) {
  onSnapshot(doc(db, 'usersDelivery', userId), (docSnap) => {
      if (docSnap.exists()) {
          console.log('Current data:', docSnap.data());
          // Handle the updated data here
      } else {
          console.log('No such document!');
      }
  }, (error) => {
      console.error('Error listening for changes:', error);
  });
}



// Example usage

//setItem(userId, 'userID', userId)

  //const userID = userData.uid
  
  // For registration and login, we always convert the email to lower case
  // so the user can login with HELLO@gmail.com and hello@gmail.com
  // because we always deal with only one case
  const loginEmail = email.toLowerCase();

  // query, collection and where
  // are Firebase functions for retrieving data from the database
  // Basically, the "query" function starts a new query,
  // the "collection" function specifies which collection (like stores, orders, etc.)
  // and the "where" function lets us say that the field must be equal to a variable.
  const q = query(collection(db, "stores"), where("email", "==", loginEmail));

  // Send the query off to Firebase.
  // What we get back is a snapshot containing all of the data that matches the query.
  const querySnapshot = await getDocs(q);

  // We check if there are no matching records in the snapshot.
  // If there aren't, that means there's no matching email on the database,
  // so we throw an error.
  // (You can handle this error at the function you call login from.)
  if (querySnapshot.docs.length === 0) {
    //localStorage.setItem('emailPopup', email_name);
    //setItem(userId, 'emailPopup', email_name)
    var displayElement = document.getElementById("displayEmail");

  // Set the innerHTML of the element to the value of the JavaScript variable
  displayElement.innerHTML = email;

  const passMessage = document.getElementById('invalid_password');
  passMessage.style.display = "none";

  const accountMessage = document.getElementById('invalid_account');
  accountMessage.style.display = "none";

  const emailMessage = document.getElementById('invalid_email');
  emailMessage.style.display = "flex";
    throw new Error(`No account with email ${email} found.`);

  }

  // The two lines below load the user (store) data from the snapshot.
  const usersDeliverynapshot = querySnapshot.docs[0];
  const userData = usersDeliverynapshot.data();

  // This function call checks if the password argument given to the function
  // matches the encrypted password in the userData
  // If it is the same, the user can login because the password is correct,
  // but if it is different, we have to show an invalid-password error.
  const isPasswordCorrect = await bcrypt.compare(password, userData.password);

  // Throw an error which we handle at the call site (where we call the function from)
  // that the password is incorrect.
  if (!isPasswordCorrect) {
    //localStorage.setItem('pass', email_name);
    //setItem(userId, 'pass', email_name);
    const emailMessage = document.getElementById('invalid_email');
  emailMessage.style.display = "none";

  const accountMessage = document.getElementById('invalid_account');
  accountMessage.style.display = "none";

  const passMessage = document.getElementById('invalid_password');
  passMessage.style.display = "flex";
    throw new Error("Sorry, incorrect password.");
  }

  // We check if the store is active (if it has been approved by an admin from CarryBags).
  // If it's inactive, we have to return an error 
  // and handle that error at the call site.
  if (!userData.isActive) {
    //localStorage.setItem('account', email_name);
    //setItem(userId, 'account', email_name);
    const passMessage = document.getElementById('invalid_password');
  passMessage.style.display = "none";

  const emailMessage = document.getElementById('invalid_email');
  emailMessage.style.display = "none";

  const accountMessage = document.getElementById('invalid_account');
  accountMessage.style.display = "flex";
    throw new Error(
      "Your account is currently in the queue for us to review and approve. Please contact us at support@wecarrybags.co.uk if there are any issues."
    );
  }

// Function to sanitize email to be used as document ID

  //localStorage.setItem(`${userID}email`, email_name);
  //localStorage.setItem('signedIn', 'true');
/*
  const email1 = getItem(userId, 'emailPopup').then(value => {
    console.log('Retrieved value:', value); 
  });
  console.log(email1)

  if (email1 !== null) {
  // JavaScript variable

  // Get the HTML element by its ID
  var displayElement = document.getElementById("displayEmail");

  // Set the innerHTML of the element to the value of the JavaScript variable
  displayElement.innerHTML = email1;

  const passMessage = document.getElementById('invalid_password');
  passMessage.style.display = "none";

  const accountMessage = document.getElementById('invalid_account');
  accountMessage.style.display = "none";

  const emailMessage = document.getElementById('invalid_email');
  emailMessage.style.display = "flex";
  //localStorage.removeItem('emailPopup');
  removeItem(userId, 'emailPopup');
  }

  const pass = getItem(userId, 'pass').then(value => {
    console.log('Retrieved value:', value); 
  });
  if (pass !== null) {
  // JavaScript variable

  const emailMessage = document.getElementById('invalid_email');
  emailMessage.style.display = "none";

  const accountMessage = document.getElementById('invalid_account');
  accountMessage.style.display = "none";

  const passMessage = document.getElementById('invalid_password');
  passMessage.style.display = "flex";
  //localStorage.removeItem('pass');
  removeItem(userId, 'pass');
  }
  

  const account = getItem(userId, 'account').then(value => {
    console.log('Retrieved value:', value); 
  });
  if (account !== null) {
  // JavaScript variable

  const passMessage = document.getElementById('invalid_password');
  passMessage.style.display = "none";

  const emailMessage = document.getElementById('invalid_email');
  emailMessage.style.display = "none";

  const accountMessage = document.getElementById('invalid_account');
  accountMessage.style.display = "flex";
  //localStorage.removeItem('account');
  removeItem(userId, 'account');
  }
  */

  // We've checked the possible cases that may make a store unable to login,
  // and it has passed those cases,
  // so we can perform any other login logic we need.
  // This example code saves the store's data to the browser as a cookie
  // which we can fetch later.
  //localStorage.setItem("store_data", JSON.stringify(userData));
  const userID = userData.uid
  sessionStorage.setItem('ID', userID);

  async function saveDataAndRedirect(userId, key, value, redirectUrl) {
    await setItem(userId, key, value);
    //Implement in clearForms
    /*
    for (let i = 1; i <= 10; i++) {
    await setItems(userId, ['recipientName' +  i], "")
    }
    */
    window.location.href = redirectUrl;
  }


saveDataAndRedirect(userId, 'email', email_name, "https://delivery.wecarrybags.co.uk/shipping");



  //setItem(userId, 'email', email_name)
  //console.log(userID) 
  //console.log(userId)
  //sessionStorage.setItem('ID', userID);
  //window.location.href = "https://delivery.wecarrybags.co.uk/shipping";
  } else {
    event.preventDefault();
  }

}
document.addEventListener("DOMContentLoaded", () => {
  const loginButton = document.getElementById("loginButton");
  loginButton.addEventListener("click", login);

});

function preventNavigation() {
  // Push a dummy state on page load
  window.history.pushState(null, '', window.location.href);

  // Add an event listener for the popstate event
  window.addEventListener('popstate', function() {
      // Push the state again to ensure back navigation is disabled
      window.history.pushState(null, '', window.location.href);
      
      // Optional: Display a message or take any other action
      

      // Clear forward history
      window.history.replaceState(null, '', window.location.href);
  });
}

// Call the function to prevent back navigation on page load
window.onload = preventNavigation;


const forgotPassword = document.getElementById('forgotPassword');

forgotPassword.addEventListener('click', function(event) {
console.log("a")
event.preventDefault();
console.log("b")
window.location.href = "https://delivery.wecarrybags.co.uk/forgot-password";
});